import $ from "jquery";

export function accordion() {
    $(document.querySelector('.accordion__title')).addClass('active');
    $('.application__accordion').find('.accordion__title').removeClass('active');
    $('.accordion__title').click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this)
                .parents('.accordion__item')
                .find('.accordion__text')
                .slideUp();
        } else {
            $('.accordion__title').removeClass('active');
            $(this).addClass('active');
            $('.accordion__text').slideUp();
            $(this)
                .parents('.accordion__item')
                .find('.accordion__text')
                .slideDown();
        }
    });
}