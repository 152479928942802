import $ from "jquery";

export function mobileMenu () {

    const openMenu =  function  () {
        $('.header__hidden-menu').addClass('show');
        $('.overlay').addClass('active');
        $('body').addClass('fix');
    };

    const closeMenu =  function  () {
        $('.header__hidden-menu').removeClass('show');
        $('.overlay').removeClass('active');
        $('body').removeClass('fix');
    };

    $('.menu-open__button').click(function(){
        openMenu();
    });

    $('.menu-close__button').click(function(){
        closeMenu();
    });

    $('.overlay').click(function(){
        closeMenu();
    });
    let ww = $(window).width();
    $(window).resize(function () {
        ww = $(window).width();
    });
    $('.submenu_wrapp .icon--menu-toggle').click(function(){
        if(ww <= 960) {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parents('.submenu_wrapp').find('.submenu').slideUp('slow');
            }
            else {
                $('.submenu').each(function () {
                    $(this).slideUp('slow').parents('li').find('.icon--menu-toggle').removeClass('active');
                });
                $(this).addClass('active').parents('.submenu_wrapp').find('.submenu').slideDown('slow');
            }
            return false;
        }
    });
}