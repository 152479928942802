export function checkWebp() {
    function canUseWebp() {
        let elem = document.createElement("canvas");
        if (!!(elem.getContext && elem.getContext("2d"))) {
            return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
        }
        return false;
    }

    let images = document.querySelectorAll("[data-bgs]");
    for (let i = 0; i < images.length; i++) {
        let image = images[i].getAttribute("data-bgs");
        images[i].style.backgroundImage = "url(" + image + ")";
    }

    let isitFirefox = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    let firefoxVer = isitFirefox ? parseInt(isitFirefox[1]) : 0;

    if (canUseWebp() || firefoxVer >= 65) {
        let imagesWebp = document.querySelectorAll("[data-bgs-webp]");
        for (let i = 0; i < imagesWebp.length; i++) {
            let imageWebp = imagesWebp[i].getAttribute("data-bgs-webp");
            imagesWebp[i].style.backgroundImage = "url(" + imageWebp + ")";
        }
    }
}