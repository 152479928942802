import $ from "jquery";
import svg4everybody from "svg4everybody";
import baguetteBox from "baguettebox.js";
import {
    mainSlider,
    servicesSlider,
    rightSlider,
    materialSlider,
    clientSlider,
    newsSlider,
    guaranteesSlider,
} from "./modules/sliders";

import {
    portfolioThumbsSlider,
    portfolioRelatedSlider,
} from "./modules/portfoliSliders";

import {
    portfolioModals
} from "./modules/portfolioModals";

import {
    checkWebp
} from "./modules/webpBg";

import {
    modals
} from "./modules/modals";

import {
    masonry
} from "./modules/masonry";

import {
    mobileMenu
} from "./modules/mobileMenu";

import {
    accordion
} from "./modules/accordion";

import {
    select,
} from "./modules/select";

import {
    quiz,
    checkFields
} from "./modules/quiz";

import {
    uploadFile
} from "./modules/uploadFile";

import {
    popUpForm
} from "./modules/popUpForm";

import {
    search
} from "./modules/search";

import {
    up
} from "./modules/up";

$(function () {
    svg4everybody();
    checkWebp();
    modals();
    mainSlider();
    servicesSlider();
    rightSlider();
    materialSlider();
    clientSlider();
    newsSlider();
    guaranteesSlider();
    masonry();
    mobileMenu();
    accordion();
    portfolioThumbsSlider();
    portfolioRelatedSlider();
    portfolioModals();
    quiz();
    checkFields();
    select();
    uploadFile();
    popUpForm();
    search();
    up();
});