export function popUpForm(){
    function formsInit(formClass, buttonClass){
        const form = document.querySelector(formClass);
        const closeForm = form.querySelector('.form_close');
        document.addEventListener('click', (e) => {
            const target = e.target;
            if (target && target.classList.contains(buttonClass) || target.closest('.'+ buttonClass+'')) {
                e.preventDefault();
                const title = target.closest('.'+ buttonClass+'').dataset.name;
                const button = target.closest('.'+ buttonClass+'').dataset.button;
                const formTitle = form.querySelector('.popup-form__title');
                const input = form.querySelector('input[name="button"]');
                formTitle.innerHTML = title
                input.value = button;
                form.classList.add('active');
            }
        });
        closeForm.addEventListener('click', function(){
            $(form).removeClass('active');
        })
    }
    formsInit('.popup', 'open-popup');
    formsInit('.popup-contact', 'open-popup-contact');
    const formSuccess = document.querySelector('.popup_success');
    const closeformSuccess = formSuccess.querySelector('.form_close');
    closeformSuccess.addEventListener('click', function(){
        $(formSuccess).removeClass('active');
    })
}