import Swiper from "swiper/swiper-bundle";

export function mainSlider() {
    const swiper = new Swiper(".image-slider .swiper", {
        loop: true,
        watchSlidesProgress: true,
        effect: "fade",
        speed: 600,
        allowTouchMove: false,
    });
    const swiperThumb = new Swiper(".content-slider .swiper", {
        loop: true,
        speed: 600,
        effect: "creative",
        creativeEffect: {
            prev: {
                shadow: false,
                origin: "left center",
                translate: ["-5%", 0, -200],
                rotate: [0, 100, 0],
            },
            next: {
                origin: "right center",
                translate: ["5%", 0, -200],
                rotate: [0, -100, 0],
            },
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });
}

export function servicesSlider() {
    const swiper = new Swiper(".services-slider .swiper", {
        slidesPerView: 4,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
}

export function guaranteesSlider() {
    const swiper = new Swiper(".guarantees-slider .swiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            380: {
                slidesPerView: 2,
            },
            580: {
                slidesPerView: 3,
            },
            1000: {
                slidesPerView: 4,
            },
            1300: {
                slidesPerView: 5,
            }
        }
    });
}

export function materialSlider() {
    const swiper = new Swiper(".material-slider .swiper", {
        loop: true,
        autoplay: {
            delay: 2000,
        },
        slidesPerView: 2,
        spaceBetween: 0,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            420: {
                slidesPerView: 2
            },
            580: {
                slidesPerView: 3
            },
            1000: {
                slidesPerView: 4
            }
        }
    });
}

export function clientSlider() {
    const swiper = new Swiper(".client-slider .swiper", {
        loop: true,
        autoplay: {
            delay: 2000,
        },
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 20,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            420: {
                slidesPerView: 2,
                slidesPerGroup: 2
            },
            580: {
                slidesPerView: 3,
                slidesPerGroup: 3
            },
            1000: {
              slidesPerView: 4,
              slidesPerGroup: 4
            },
            1300: {
                slidesPerView: 6,
                slidesPerGroup: 6
            }
        }
    });
}

export function newsSlider() {
    const swiper = new Swiper(".news-slider .swiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        420: {
            slidesPerView: 2
        },
        breakpoints: {
            760: {
                slidesPerView: 3
            }
        }
    });
}

export function rightSlider() {
    const swiper = new Swiper(".right-slider .swiper", {
        slidesPerView: 1.5,
        spaceBetween: 0,
        freeMode: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            420: {
                slidesPerView: 2.5
            },
            580: {
                slidesPerView: 3.5
            }
        }
    });
}