export function uploadFile(){
    const parents = document.querySelectorAll('.uploadFile');
    parents.forEach(parent => {
        const inputFiles = parent.querySelectorAll('.upload-file__input');
        const textSelector = parent.querySelector('.upload-file__text');
        inputFiles.forEach(function(el) {
            
            let fileList;


            el.addEventListener('change', function (e) {

                // создаём массив файлов 
                fileList = [];
                for (let i = 0; i < el.files.length; i++) {
                    fileList.push(el.files[i]);
                }

                fileList.forEach(file => {
                    uploadFile(file);
                });
            });


            const uploadFile = (file) => {

                if (file && file.length > 1) {
                    if ( file.length <= 4 ) {
                        textSelector.textContent = `Выбрано ${file.length} файла`;
                    }
                    if ( file.length > 4 ) {
                        textSelector.textContent = `Выбрано ${file.length} файлов`;
                    }
                } else {
                    textSelector.textContent = file.name;
                    console.log(file)
                    const closeIcon = parent.querySelector('.icon__fileClose');
                    const closeFile = parent.querySelector('.fileName');
                    $('.icon__fileClose').addClass('active');
                    closeFile.addEventListener('click', function(){
                        file = false;
                        
                        textSelector.textContent = '';
                        $('.icon__fileClose').removeClass('active');
                        console.log(file)
                    })
                }
            };
        });
    })
}