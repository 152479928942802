const checkboxChecked = () => {
    const quizContentActive = document.querySelector('.quiz_wrap.active');
    const checkBoxes = quizContentActive.querySelectorAll('.quiz__input:checked');

    return !!checkBoxes.length;
};
const inputsChecked = () => {
    const quizTab = document.querySelector('.quiz_wrap.active');
    const inputs = quizTab.querySelectorAll('.quiz_text');
    let inputsArr = [];
    inputs.forEach(input => {
        inputsArr.push(input);
    });

    const inp = (elem) => elem.value.trim().length;
    return inputsArr.some(inp);
};
export function quiz(){
    //Переменные
    const quiz = document.querySelector('.quiz');
    const closeQuiz = document.querySelector('.quiz_close');
    const politic = document.querySelector('.quiz_politic');
    const btnNext = document.querySelector('.quiz__button-next');
    const btnPrev = document.querySelector('.quiz__button-prev');
    const quizContent = document.querySelectorAll('.quiz_wrap');
    const line = document.querySelector('.quiz__line');
    const lineVertical = document.querySelector('.quiz__verticalSteps-content');
    const allStep = document.querySelector('.quiz_allStep');
    const currentStep = document.querySelector('.quiz_currentStep');
    const body = document.querySelector('body');
   
    //Открыть квиз
    const quizOpen = (e) => {
        if (e.target && e.target.classList.contains('quiz_open') || e.target.closest('.quiz_open')) {
            $(quiz).addClass('active');
            $(body).addClass('overflow-hidden');
            //Заблокировать кнопки по умолчанию
            btnNext.disabled = true;
            btnPrev.disabled = true;
            //Подсчитать количесвто шагов в квизе
            let step = 0;
            let current = 0;
            quizContent.forEach(item=>{
                step++;
                if (item.classList.contains('active')) {
                    current = step; 
                }
                let lineItems = line.querySelectorAll('.quiz__line-element');
                if(lineItems.length < quizContent.length){ 
                    line.innerHTML+='<div class="quiz__line-element"></div>';
                    lineVertical.innerHTML+='<div class="quiz__step"><span>'+ step +'</span><div class="quiz_verticalLine"></div></div>';
                }
            })
            currentStep.textContent = current;
            allStep.textContent = step;
            let lineStep = current - 1;
            const lineItems = line.querySelectorAll('.quiz__line-element');
            const lineItemsVertical = lineVertical.querySelectorAll('.quiz__step')
            $(lineItemsVertical[lineStep]).addClass('active');
            $(lineItems[lineStep]).addClass('active last');
            for(let i = 0; i < lineStep; i++){
                $(lineItems[i]).addClass('active');
                $(lineItemsVertical[i]).addClass('active');
            }
            btnNext.disabled = !(checkboxChecked() || inputsChecked());
            //Анимация элементов при нажатии
            const elements = document.querySelectorAll('.quiz__element');
            const bonus = document.querySelectorAll('.quiz__bonus');
            const connection = document.querySelectorAll('.connection__element');
            elements.forEach(item =>{
                item.addEventListener('click',function(){
                    $('.quiz_wrap.active').find('.quiz__element').removeClass('active');
                    $(item).addClass('active');
                })
            })
            bonus.forEach(item =>{
                item.addEventListener('click',function(){
                    $('.quiz_wrap.active').find('.quiz__bonus').removeClass('active');
                    $(item).addClass('active');
                })
            })
            connection.forEach(item =>{
                item.addEventListener('click',function(){
                    $('.quiz_wrap.active').find('.connection__element').removeClass('active');
                    $(item).addClass('active');
                })
            })

        }
    };
    document.addEventListener('click', quizOpen);
    const nextQuiz = (e) => {
        if (e.target && e.target.classList.contains('quiz__button-next') || e.target.closest('.quiz__button-next')) {
            const parent = e.target.closest('.quiz');
            const steps = parent.querySelectorAll('.quiz_wrap');
            const currentNum = parent.querySelector('.quiz_currentStep');
            const nextButton = parent.querySelector('.quiz__button-block .quiz__button-next');
            const prevButton = parent.querySelector('.quiz__button-block .quiz__button-prev');
            const buttonSteps = parent.querySelector('.quiz__button-block');
            const buttonSend = parent.querySelector('.quiz_button_block_send');
            const iconColor = parent.querySelector('.iconColor');
            let line = parent.querySelector('.quiz__line');
            const lineItems = line.querySelectorAll('.quiz__line-element');
            const lineVertical = document.querySelector('.quiz__verticalSteps-content');
            const lineItemsVertical = lineVertical.querySelectorAll('.quiz__step')
            let current = null;
            nextButton.disabled = true;
            steps.forEach((item, index) => {
                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                    current = index + 1;
                }
            });
            currentNum.textContent = current + 1;
            steps[current].classList.add('active');
            lineItems.forEach((item, index) => {
                item.classList.remove('last');
                if(index === current) {
                    item.classList.add('active');
                    item.classList.add('last');
                }
            });
            lineItemsVertical.forEach((item, index) => {
                if(index === current) {
                    item.classList.add('active');
                    item.classList.add('last');
                }
            });
            if(current) {
                prevButton.disabled = false;
            }
            if(current + 1 === steps.length) {
                buttonSteps.style.display = 'none';
                buttonSend.style.display = 'flex';
                document.querySelector('.quiz__verticalSteps').style.background = '#fc3b50';
                //iconColor.style.fill = '#41BBB4';

            }
            nextButton.disabled = !(checkboxChecked() || inputsChecked());
        }
    };

    const prevQuiz = (e) => {
        if (e.target && e.target.classList.contains('quiz__button-prev') || e.target.closest('.quiz__button-prev')) {
            const parent = e.target.closest('.quiz');
            const steps = parent.querySelectorAll('.quiz_wrap');
            const currentNum = parent.querySelector('.quiz_currentStep');
            const nextButton = parent.querySelector('.quiz__button-block .quiz__button-next');
            const prevButton = parent.querySelector('.quiz__button-block .quiz__button-prev');
            const buttonSteps = parent.querySelector('.quiz__button-block');
            let line = parent.querySelector('.quiz__line');
            const buttonSend = parent.querySelector('.quiz_button_block_send');
            const lineItems = line.querySelectorAll('.quiz__line-element');
            //const lineVerticalBg = document.querySelector('.quiz__verticalSteps')
            const lineVertical = document.querySelector('.quiz__verticalSteps-content');
            const lineItemsVertical = lineVertical.querySelectorAll('.quiz__step')
            const iconColor = parent.querySelector('.iconColor');
            let current = null;
            nextButton.disabled = true;
            steps.forEach((item, index) => {
                if (item.classList.contains('active')) {
                    item.classList.remove('active');
                    current = index - 1;
                }
            });
            currentNum.textContent = current + 1;
            steps[current].classList.add('active');
           if( current==0){
            prevButton.disabled = true;
           }
            else if(!current && current!=0) {
                prevButton.disabled = false;
            }
           
            lineItems.forEach((item, index) => {
                //console.log(index);
                if(index === current+1) {
                    //console.log(index);
                    $(lineItems[index-1]).addClass('last')
                    item.classList.remove('active');
                    item.classList.remove('last');
                }
            });
            lineItemsVertical.forEach((item, index) => {
                if(index === current+1) {
                    item.classList.remove('active');
                    item.classList.remove('last');
                }
            });
            buttonSteps.style.display = 'flex';
            if(current + 1 < steps.length) {
                buttonSend.style.display = 'none';
                document.querySelector('.quiz__verticalSteps').style.background = 'transparent';
                //iconColor.style.fill = '#A3AFB5';

            }
            nextButton.disabled = !(checkboxChecked() || inputsChecked());
        }
    };

    document.addEventListener('click', nextQuiz);
    document.addEventListener('click', prevQuiz);
    //Закрыть квиз
    closeQuiz.addEventListener('click', function(){
        $(quiz).removeClass('active');
        $(body).removeClass('overflow-hidden');
        close = false;
    })
}
export function checkFields() {
    const checkCheckboxes = (e) => {
        if (e.target &&
            e.target.classList.contains('quiz_input_check') ||
            e.target.closest('.quiz_input_check') ||
            e.target.classList.contains('quiz_radio') ||
            e.target.closest('.quiz_radio')) {
            if (e.target.tagName === 'LABEL' || e.target.tagName === 'SPAN') {
                return;
            }
            const parent = e.target.closest('.quiz');
            const nextButton = parent.querySelector('.quiz__button-next');
            nextButton.disabled = !(checkboxChecked() || inputsChecked());

        }
    };
    document.addEventListener('click', checkCheckboxes);

    const checkInputsText = (e) => {
        if (e.target && e.target.classList.contains('quiz-text')) {

        }else{
            const parent = e.target.closest('.quiz');
            const nextButton = parent.querySelector('.quiz__button-next');
            nextButton.disabled = !(checkboxChecked() || inputsChecked());
        }
    };
    document.addEventListener('input', checkInputsText);
}