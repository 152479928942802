import Swiper from "swiper/swiper-bundle";

export function portfolioThumbsSlider() {
    const swiper = new Swiper(document.querySelector(".portfolio-slider--thumb"), {
        loop: false,
        spaceBetween: 20,
        slidesPerView: 3,
        watchSlidesProgress: true,
        breakpoints: {
            450: {
                slidesPerView: 4,
            },
            1000: {
                slidesPerView: 6,
            }
        }
    });
    const swiper2 = new Swiper(document.querySelector(".portfolio-slider"), {
        loop: false,
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: swiper,
        },
    });
}

export function portfolioRelatedSlider() {
    const swiper = new Swiper(document.querySelector(".portfolio-related-slider .swiper"), {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            450: {
                slidesPerView: 2,
            },
            1000: {
                slidesPerView: 3,
            }
        }
    });
}