export function select(){
    let select = function () {
        let selectHeader = document.querySelectorAll('.select__header-s');
        let selectItem = document.querySelectorAll('.select__item-s');
    
        selectHeader.forEach(item => {
            item.addEventListener('click', selectToggle)
        });
    
        selectItem.forEach(item => {
            item.addEventListener('click', selectChoose)
        });
    
        function selectToggle() {
            this.parentElement.classList.toggle('is-active');
        }
    
        function selectChoose() {
            let text = this.innerText,
                select = this.closest('.select-s'),
                currentText = select.querySelector('.select__current');
            currentText.innerText = text;
            select.classList.remove('is-active');
    
        }
    
    };
    select();
}