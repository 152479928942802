import Swiper from "swiper/swiper-bundle";

export function portfolioModals() {

    const openPortfolioModal = function (e) {
        const body = document.querySelector('body');
        let portfolioSliderThumb;
        let portfolioSlider;
        let portfolioRelatedSlider;
        let modalToggle = false;


        const openPopup =  function (e) {
            const parent = e.target.closest('.masonry__item-m');
            const galleryThumbs = parent.querySelector('.portfolio-slider--thumb');
            const galleryTop = parent.querySelector('.portfolio-slider');
            const sliderRelated = parent.querySelector('.portfolio-related-slider .swiper');
            body.classList.add('fix');
            parent.querySelector('.modal').classList.add('active');
            parent.querySelector('.modal__body').classList.add('active');
            console.log(parent);
            portfolioSliderThumb = new Swiper(galleryThumbs, {
                loop: false,
                spaceBetween: 20,
                slidesPerView: 3,
                watchSlidesProgress: true,
                breakpoints: {
                    450: {
                        slidesPerView: 4,
                    },
                    1000: {
                        slidesPerView: 6,
                    }
                }
            });

            portfolioSlider = new Swiper(galleryTop, {
                loop: false,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                thumbs: {
                    swiper: portfolioSliderThumb,
                },
            });

            portfolioRelatedSlider = new Swiper(sliderRelated, {
                slidesPerView: 1,
                spaceBetween: 30,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                breakpoints: {
                    
                    450: {
                        slidesPerView: 2,
                    },
                    1000: {
                        slidesPerView: 3,
                    }
                }
            });
            const lazyImg = document.querySelectorAll('[data-lazy-src]');
            lazyImg.forEach(item => {
                item.attributes[0].value = item.dataset.lazySrc;
            });

            modalToggle = true;
        };

        const closePopup = function () {
            const modals = document.querySelectorAll('.modal');
            const modalsBody =  document.querySelectorAll('.modal__body');
            modals.forEach(item => {
                item.classList.remove('active');
            });
            modalsBody.forEach(item => {
                item.classList.remove('active');
            });
            document.body.classList.remove('fix');
            portfolioRelatedSlider.destroy(true, true);
            portfolioSlider.destroy(true, true);
            portfolioSliderThumb.destroy(true, true);
            modalToggle = false;
        };


        openPopup(e);


        window.onkeydown = function (event) {
            if (event.keyCode == 27 && modalToggle) {
               closePopup();
            }
        };

        document.addEventListener('click', (e) => {
            if(e.target.classList.contains('modal-close') || e.target.closest('.modal-close') || e.target.classList.contains('modal') ) {
                closePopup();
            }
        });

    };

    document.addEventListener('click', (e) => {
        if (e.target.classList.contains('portfolio-popup__open') || e.target.closest('.portfolio-popup__open')) {
            e.preventDefault();
            openPortfolioModal(e);
        }
    });

}